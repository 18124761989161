import '~/public/assets/styles/non-critical-voices.scss';
import './hydrator';
import './youtube';
import './tracky';
import addNavListener from './nav';
import './ads';
import './right-rail-sticky';
import './right-rail-sticky-ads';
import './openable';
import './b-page-newsletter';
import './hp';
import './spotim';
import './share';
import './amazon';
import './getClientUUID';
import './abeagle';
import './liveramp';
import './abOutbrainTest';
import './recirc';
import getProfile from './profile';
import './cmp-util';
import './membership';
import './replaceXid';

addNavListener();

window.getProfile = getProfile;
window.HP.params.abOutbrainTest.ready = true;
window.HP.params.abOutbrainTest.doNotPublishEvent = true;
